const axios = require('axios');
const qs = require('qs');
let visitorInfoRegistering = ({role,env,baseUrlToUse,username}) => {
    let data =qs.stringify({
        username:username,
        hostname: window.location.hostname,
        browser: window.navigator.userAgent,
        language: window.navigator.language,
        screen: window.screen.width + "x" + window.screen.height,
        time: new Date().toLocaleString(),
        role:role,
        env:env
    })
    let registrationAction =(data,baseUrlToUse)=>{
        axios.post(`${baseUrlToUse}/visitorInfoRegistering`, data).then(response => {
           
        }).catch((error) => {
            console.log(error);
        })
    }
    if(localStorage.getItem("lastRegistration")!==null){
        let lastRegistration = parseInt(localStorage.getItem("lastRegistration"))   
        let now = new Date().getTime();
        
        if(now-lastRegistration>=3600000){
            localStorage.setItem("lastRegistration",now)
            return registrationAction(data,baseUrlToUse)
        }
        return null;
    }
    let now = new Date().getTime();
    localStorage.setItem("lastRegistration",now)
    return registrationAction(data,baseUrlToUse)

}

export default visitorInfoRegistering;